import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import Image from 'next/image';
import BasicButton from '../BasicButton/BasicButton';
import TextInput from '../TextInput/TextInput';
import { useRouter } from 'next/router';
import TextTransition, { presets } from 'react-text-transition';
import classNames from "classnames";

const TEXTS = ['E-commerce', 'Insurance', 'FinTech', 'You'];

const Hero = () => {
  const [domainAddress, setDomainAddress] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [ref, setRef] = useState<string | null>('');
  const [index, setIndex] = useState(0);
  const getDomainAddressValue = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setDomainAddress(inputValue);
  };

  const router = useRouter();

  useEffect(() => {
    if (router.query.ref) {
      localStorage.setItem('ref', router.query.ref as string);
    }

    if (localStorage.getItem('ref')) {
      setRef(localStorage.getItem('ref'));
    }
  }, [router.query.ref]);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 3000);
    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    if (domainAddress.length && isInvalid) {
      const check = validateDomain(domainAddress);

      if (check) {
        setIsInvalid(false);
      }
    }
  }, [domainAddress]);

  const validateDomain = (domain: string) => {
    const regex = new RegExp(
      /^(?:(?:https?):\/\/)?(?:www\.)?([a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,}$/i
    );

    return regex.test(domain);
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const check = validateDomain(domainAddress);

    if (!check) {
      setIsInvalid(true);
      return;
    }

    window.location.href = (`${process.env.NEXT_PUBLIC_CUX_APP_URL}/register?domain=${domainAddress}${ref && `&ref=${ref}`}`);
  }

  return (
    <div className='hero'>
      <div className='hero-container'>
        <div className='hero__text-box-wrapper'>
          <div className='hero__main-text'>
            <h1 className='hero__title'>
              The Digital Experience <br />
              Analytics Tool for
              <div className='hero__title--transition-container'>
                <TextTransition springConfig={presets.wobbly}>
                  {TEXTS[index % TEXTS.length]}
                </TextTransition>
              </div>
            </h1>
            <p className='hero__subtitle'>
              It’s not about data. It’s about users’ real problems.
            </p>
          </div>

          <div className='hero__lets-try-box lets-try-box'>
            <div className='lets-try-box__form-container'>
              <form
                action={`${process.env.NEXT_PUBLIC_CUX_APP_URL}/register?domain=${domainAddress}${
                  ref && `&ref=${ref}`
                }`}
                method='get'
                className={classNames('lets-try-box__form', {
                  'lets-try-box__form--error': isInvalid,
                })}
                onSubmit={handleSubmit}
              >
                <TextInput
                  placeholder='yourdomain.com'
                  value={domainAddress}
                  onChange={getDomainAddressValue}
                  id='start_trial_hero_input'
                />
                <BasicButton
                  id='start_trial_hero'
                  type="submit"
                  disabled={isInvalid}
                  content='start free trial'
                />
              </form>
              {isInvalid && (
                <p className='lets-try-box__form-error-message'>
                  Ooops, it doesn&apos;t look like a valid domain address.<br/>
                  If you think it&apos;s a mistake, please drop us a line at <a target="_blank" rel="noreferrer nooppener" href={process.env.NEXT_PUBLIC_CUX_SUPPORT_TICKET_URL}>support@cux.io</a>
                </p>
              )}
            </div>
            <div className='lets-try-box__text-container-card'>
              <p className='lets-try-box__text-info'>
                No credit card required. No commitment.
              </p>
            </div>
          </div>
        </div>
        <div className='hero__image-container'>
          <Image
            src='/hero_photo.jpg'
            alt='hero image'
            width={685}
            height={518}
            objectFit='cover'
            className='hero__image'
            priority
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
