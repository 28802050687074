interface Props {
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

const TextInput = (props: Props) => {
  const { value, onChange, placeholder, id } = props;

  return (
    <input
      type='text'
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className='text-input'
      id={id}
    />
  );
};

export default TextInput;
