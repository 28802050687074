import type { NextPage } from 'next';
import Head from 'next/head';
import Hero from '../src/components/Hero/Hero';
import FoundersSection from '../src/components/FoundersSection/FoundersSection';
import LogoSlider from '../src/components/LogoSlider/LogoSlider';
import axios from 'axios';
import HomePageReviews from '../src/components/HomePageReviews/HomePageReviews';
import { homePageReviews } from '../src/components/HomePageReviews/HomePageReviewsData';
import SelfGuidedWalkthrough from '../src/components/SelfGuidedWalkthrough/SelfGuidedWalkthrough';
import { homePageStories } from '../src/components/HomePageStories/HomePageStoriesData';
import HomePageStories from '../src/components/HomePageStories/HomePageStories';
import HomePageWithCux from '../src/components/HomePageWithCux/HomePageWithCux';
import HomePageVideo from '../src/components/HomePageVideo/HomePageVideo';
import AvocadoBreakSection from '../src/components/AvocadoBreakSection/AvocadoBreakSection';
import HomePageFeatures from '../src/components/HomePageFeatures/HomePageFeatures';
import HomePageTimeline from '../src/components/HomePageTimeline/HomePageTimeline';

type PageProps = {
  allClients: number;
};

const Home: NextPage<PageProps> = ({ allClients }) => {
  const reviewsSectionData = {
    title: 'Over 1,200 companies cannot be wrong!',
    subtitle:
      'Providing a high-quality insights and expert knowledge is our bread and butter (and a bit of avocado). See how our customers feels about that:',
    reviews: homePageReviews,
  };
  const storiesSectionData = {
    title: 'Customer Success Stories',
    stories: homePageStories,
  };

  const videoSectionData = {
    title: `Why CUX is a game-changer?`,
    videoUrl: 'https://www.youtube.com/embed/DKRlXAhxink',
  };

  return (
    <>
      <Head>
        <title>cux.io - the first analytical tool for UX automation</title>
        <meta
          name='description'
          content="We translate your visitors' experiences into numbers, recognize their behaviour patterns all along the customer's journey and provide instant actionable insights. No more Data SPAM"
        />
      </Head>

      <main style={{ overflowX: 'hidden' }}>
        <Hero />
        <LogoSlider allClients={allClients} />
        <SelfGuidedWalkthrough />
        <HomePageTimeline />
        <HomePageVideo data={videoSectionData} />
        <HomePageFeatures />
        <AvocadoBreakSection />
        <HomePageWithCux />
        <HomePageStories data={storiesSectionData} />
        <HomePageReviews data={reviewsSectionData} />
        <FoundersSection />
      </main>
    </>
  );
};

export default Home;

export async function getServerSideProps() {
  const allClients = await axios.get(
    `https://content.cux.io/api/monthly-clients/2`
  );

  return {
    props: {
      allClients: allClients.data.data.attributes.numberOfClients,
    },
  };
}
